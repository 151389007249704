import { Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { ErrorPage } from '../../pages/modals/error/error.page';

export const IGNORE_ERRORS = new HttpContextToken<boolean>(() => false);

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private modalController: ModalController
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.context.get(IGNORE_ERRORS) === true) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('http interceptor error', error);

        let message = null;
        switch (error?.status) {
          case 403:
            // eslint-disable-next-line max-len
            message =
              'Usuário sem permissão de acesso. Certifique-se de que esteja utilizando a sua conta corporativa e que possua autorização de acesso ao aplicativo.';
            break;
          case 500:
            message =
              'Ocorreu um erro inesperado ao buscar as informações. Por favor, tente novamente mais tarde.';
            break;
        }

        if (message) {
          this.presentErrorModal(message);
        }

        return throwError(error);
      })
    );
  }

  async presentErrorModal(message: string) {
    const modal = await this.modalController.create({
      component: ErrorPage,
      cssClass: 'app-auto-height-modal',
      componentProps: {
        message,
        negativeButtonText: 'Sair',
        positiveButtonText: 'Recarregar',
      },
    });

    await modal.present();

    const { data: reloadOnly } = await modal.onWillDismiss();
    if (!reloadOnly) {
      await this.authService.signOut(false);
    }

    window.location.reload();
  }
}
